<template>
  <main className="py-5 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    
    <PageHeader title="gebruikers" subtitle="Beheer gebruikers" />

    <div class="bg-white shadow overflow-hidden sm:rounded-md mb-5 mt-6">
      <div class="sm:grid sm:grid-cols-4 sm:items-start sm:py-5">
        <label for="search" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 px-6">
          Search
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-2">
          <div class="max-w-lg flex rounded-md shadow-sm">
            <input
              type="text"
              name="search"
              id="search"
              autocomplete="search"
              class="flex-1 block w-full focus:ring-orange-500 focus:border-orange-500 min-w-0 rounded sm:text-sm border-gray-300" v-model="searchQuery" v-on:keyup.enter="search" />
          </div>
        </div>
        <div class="block text-sm font-medium text-gray-700">
          <button class="px-4 py-2 md:ml-2 border border-gray-300 text-sm font-medium rounded-md" @click="search">search</button>
          <button class="px-4 py-2 md:ml-2 border border-gray-300 text-sm font-medium rounded-md" @click="clear">
            clear
          </button>
        </div>
      </div>
    </div>

    <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
      <div className="space-y-6 lg:col-start-1 lg:col-span-2">
        
        <section aria-labelledby="applicant-information-title">
          <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
              <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                Applicant Information
              </h2>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details and application.</p>
            </div>
            
            <div
                class="bg-white overflow-hidden"
                v-if="content.length > 0"
              >
                <div class="flex flex-col">
                  <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div class="overflow-hidden">
                        <table class="min-w-full divide-y divide-gray-200">
                          <thead class="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                class="
                                  px-6
                                  py-3
                                  text-left text-xs
                                  font-medium
                                  text-gray-500
                                  uppercase
                                  tracking-wider
                                "
                              >
                                Name
                              </th>
                              <th
                                scope="col"
                                class="
                                  px-6
                                  py-3
                                  text-left text-xs
                                  font-medium
                                  text-gray-500
                                  uppercase
                                  tracking-wider
                                "
                              >
                                Office 365?
                              </th>
                              <th
                                scope="col"
                                class="
                                  px-6
                                  py-3
                                  text-left text-xs
                                  font-medium
                                  text-gray-500
                                  uppercase
                                  tracking-wider
                                "
                              >
                                Edit
                              </th>
                            </tr>
                          </thead>
                          <tbody class="bg-white divide-y divide-gray-200">
                            <tr v-for="c in content" :key="c.id">
                              <td
                                class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                              >
                                {{ c.first_name }} {{ c.last_name }}
                              </td>
                              <td
                                class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                              >
                                {{
                                  c.ms_oid ? 'Ja':'Nee'
                                }}
                              </td>
                              <td
                                class="
                                  px-6
                                  py-4
                                  whitespace-nowrap
                                  text-right text-sm
                                  font-medium
                                "
                              >
                                <router-link
                                  :to="'/users/' + c.id"
                                  class="text-orange-600 hover:text-orange-900"
                                  >edit</router-link
                                >
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Pagination
                v-if="content.length > 0"
                :page="page"
                :pages="pages"
                :itemCount="itemCount"
                :pageSize="pageSize"
                @next="next"
                @previous="previous"
              />
          </div>
        </section>
        
      </div>

      <section aria-labelledby="timeline-title" className="lg:col-start-3 lg:col-span-1">
        <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
          <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
            Meer info
          </h2>

          <div className="mt-6 flex flex-col justify-stretch">
            Iedere gebruiker heeft de rol 'user' nodig. Content editors ook de rol 'editor' toekennen. Reguliere gebruikers hebben alleen de 'user' rol nodig.
            De 'sales' rol toekennen aan BU leads and Sales t.b.v. CV's
            <!--<button
              type="button"
              className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Advance to offer
            </button>-->
          </div>
        </div>
      </section>
    </div>

  </main>
</template>

<script>
import UserService from "../../../services/admin/users/service";
import Pagination from "../../../components/Pagination";

export default {
  name: "Jobs",
  components: {
    Pagination
  },
  data() {
    return {
      content: [],
      searchQuery: null,
      page: 1,
      pages: 0,
      itemCount: 0,
      pageSize: 8,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      await UserService.getItems(this.page, this.pageSize, this.searchQuery).then(
      (response) => {
        this.content = response.data.items;
        this.pages = response.data.pages;
        this.itemCount = response.data.itemCount;
      },
      (error) => {
        this.content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        if (error.response.status === 401)
          this.$router.push(`/auth`);
      }
      );
    },
    reset() {
      this.pages = 0;
      this.itemCount = 0;
      this.page = 1;
    },
    clear() {
      this.searchQuery = null;
      this.reset();
      this.getData();
    },
    search() {
      this.reset();
      this.getData();
    },
    next() {
      this.page = this.page + 1;
      this.getData();
    },
    previous() {
      this.page = this.page - 1;
      this.getData();
    },
  },
};
</script>
